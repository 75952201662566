<template>
  <div id="qualifiedSupplier">
    <el-dialog
      :title="qualifiedSupplierFormTitle"
      width="680px"
      :visible.sync="qualifiedSupplierDialogVisible"
      :close-on-click-modal="false"
      @close="qualifiedSupplierDialogClose"
    >
      <el-form
        ref="qualifiedSupplierFormRef"
        :model="qualifiedSupplierForm"
        :rules="qualifiedSupplierFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物资名称" prop="materialName">
              <el-input v-model="qualifiedSupplierForm.materialName" placeholder="请输入物资名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物资分类" prop="type">
              <el-input v-model="qualifiedSupplierForm.type" placeholder="请输入物资分类" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合格供方名称" prop="supplierName">
              <el-input v-model="qualifiedSupplierForm.supplierName" placeholder="请输入合格供方名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产商名称" prop="manufacturer">
              <el-input v-model="qualifiedSupplierForm.manufacturer" placeholder="请输入生产商名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contactPerson">
              <el-input v-model="qualifiedSupplierForm.contactPerson" placeholder="请输入联系人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contactWay">
              <el-input v-model="qualifiedSupplierForm.contactWay" placeholder="请输入联系方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="首次列入日期" prop="firstDate">
              <el-date-picker v-model="qualifiedSupplierForm.firstDate" placeholder="请选择首次列入日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="qualifiedSupplierForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="qualifiedSupplierDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="qualifiedSupplierFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物资名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入物资名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CG_QUALIFIED_SUPPLIER_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="qualifiedSupplierPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="物资名称" />
      <el-table-column prop="type" label="物资分类" />
      <el-table-column prop="supplierName" label="合格供方名称" />
      <el-table-column prop="manufacturer" label="生产商名称" />
      <el-table-column prop="contactPerson" label="联系人" />
      <el-table-column prop="contactWay" label="联系方式" />
      <el-table-column label="首次列入日期">
        <template slot-scope="scope">
          <span v-if="scope.row.firstDate">{{ scope.row.firstDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CG_QUALIFIED_SUPPLIER_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CG_QUALIFIED_SUPPLIER_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="qualifiedSupplierPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addQualifiedSupplier,
  deleteQualifiedSupplier,
  updateQualifiedSupplier,
  selectQualifiedSupplierInfo,
  selectQualifiedSupplierList
} from '@/api/purchase/qualifiedSupplier'

export default {
  data () {
    return {
      qualifiedSupplierDialogVisible: false,
      qualifiedSupplierFormTitle: '',
      qualifiedSupplierForm: {
        id: '',
        materialName: '',
        type: '',
        supplierName: '',
        manufacturer: '',
        contactPerson: '',
        contactWay: '',
        firstDate: '',
        remarks: ''
      },
      qualifiedSupplierFormRules: {
        materialName: [{ required: true, message: '物资名称不能为空', trigger: ['blur', 'change']}]
      },
      qualifiedSupplierPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectQualifiedSupplierList(this.searchForm).then(res => {
      this.qualifiedSupplierPage = res
    })
  },
  methods: {
    qualifiedSupplierDialogClose () {
      this.$refs.qualifiedSupplierFormRef.resetFields()
    },
    qualifiedSupplierFormSubmit () {
      if (this.qualifiedSupplierFormTitle === '合格供方名录详情') {
        this.qualifiedSupplierDialogVisible = false
        return
      }
      this.$refs.qualifiedSupplierFormRef.validate(async valid => {
        if (valid) {
          if (this.qualifiedSupplierFormTitle === '新增合格供方名录') {
            this.qualifiedSupplierForm.id = ''
            await addQualifiedSupplier(this.qualifiedSupplierForm)
          } else if (this.qualifiedSupplierFormTitle === '修改合格供方名录') {
            await updateQualifiedSupplier(this.qualifiedSupplierForm)
          }
          this.qualifiedSupplierPage = await selectQualifiedSupplierList(this.searchForm)
          this.qualifiedSupplierDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.qualifiedSupplierFormTitle = '新增合格供方名录'
      this.qualifiedSupplierDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteQualifiedSupplier(row.id)
        if (this.qualifiedSupplierPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.qualifiedSupplierPage = await selectQualifiedSupplierList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.qualifiedSupplierFormTitle = '修改合格供方名录'
      this.qualifiedSupplierDialogVisible = true
      this.selectQualifiedSupplierInfoById(row.id)
    },
    handleInfo (index, row) {
      this.qualifiedSupplierFormTitle = '合格供方名录详情'
      this.qualifiedSupplierDialogVisible = true
      this.selectQualifiedSupplierInfoById(row.id)
    },
    selectQualifiedSupplierInfoById (id) {
      selectQualifiedSupplierInfo(id).then(res => {
        this.qualifiedSupplierForm.id = res.id
        this.qualifiedSupplierForm.materialName = res.materialName
        this.qualifiedSupplierForm.type = res.type
        this.qualifiedSupplierForm.supplierName = res.supplierName
        this.qualifiedSupplierForm.manufacturer = res.manufacturer
        this.qualifiedSupplierForm.contactPerson = res.contactPerson
        this.qualifiedSupplierForm.contactWay = res.contactWay
        this.qualifiedSupplierForm.firstDate = res.firstDate
        this.qualifiedSupplierForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectQualifiedSupplierList(this.searchForm).then(res => {
        this.qualifiedSupplierPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectQualifiedSupplierList(this.searchForm).then(res => {
        this.qualifiedSupplierPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectQualifiedSupplierList(this.searchForm).then(res => {
        this.qualifiedSupplierPage = res
      })
    }
  }
}
</script>

<style>
</style>
