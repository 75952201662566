import axios from '@/common/axios'
import qs from 'qs'

export function addQualifiedSupplier (data) {
  return axios({
    method: 'post',
    url: '/purchase/qualifiedSupplier/add',
    data: qs.stringify(data)
  })
}

export function deleteQualifiedSupplier (id) {
  return axios({
    method: 'delete',
    url: '/purchase/qualifiedSupplier/delete/' + id
  })
}

export function updateQualifiedSupplier (data) {
  return axios({
    method: 'put',
    url: '/purchase/qualifiedSupplier/update',
    data: qs.stringify(data)
  })
}

export function selectQualifiedSupplierInfo (id) {
  return axios({
    method: 'get',
    url: '/purchase/qualifiedSupplier/info/' + id
  })
}

export function selectQualifiedSupplierList (query) {
  return axios({
    method: 'get',
    url: '/purchase/qualifiedSupplier/list',
    params: query
  })
}
